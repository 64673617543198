import { Breadcrumb, Button, Card, Col, Drawer, Image, Modal, Row, Spin, Table, message } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { EditorForm } from '../../../Components/EditorForm';
import { axiosPost, axiosPut } from '../../../Utils/request';
import Paragraph from 'antd/es/typography/Paragraph';

export const AdminNews: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { search } = location
    const params = queryString.parse(search);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dwrLoading, setDwrLoading] = useState(false);
    const [dwr, setDwr] = useState({ title: '', width: 600, visible: false, content: null });
    const getData = (index, size) => {
        setLoading(true);
        const para = {
            relkey: params.relkey,
            key: params.key,
            index: index,
            size: size
        };
        axiosPost(5, para, 'Club', (res: any) => {
            setLoading(false);
            if (res.code === 0) {
                setData(res.data.list);
                return;
            }
            setData([]);
            return;
        })
    }
    const onAddClick = () => {
        let newsField = [{
            id: 'title',
            name: '标题',
            required: true,
            type: 'string'
        }, {
            id: 'image',
            name: '封面',
            required: true,
            multiple: false,
            type: 'file'
        }, {
            id: 'content',
            name: '详细信息',
            required: false,
            type: 'html'
        }]
        let newdwr: any = {
            title: '添加',
            visible: true,
            width: '100%',
            content: <EditorForm currentItem={null} onFinish={(v: any) => { onDataPost(v, null) }} fields={newsField} layout={{ labelCol: { span: 2 } }} />
        };
        setDwr(newdwr);
    }
    const onItemEditClick = (item) => {
        let newItem: any = JSON.parse(JSON.stringify(item));
        newItem.image = [item.image];
        let newsField = [{
            id: 'title',
            name: '标题',
            required: true,
            type: 'string'
        }, {
            id: 'image',
            name: '封面',
            required: true,
            multiple: false,
            type: 'file'
        }, {
            id: 'content',
            name: '详细信息',
            required: false,
            type: 'html'
        }]
        let newdwr: any = {
            title: '修改',
            visible: true,
            width: '100%',
            content: <EditorForm currentItem={newItem} onFinish={(v: any) => { onDataPost(v, newItem) }} fields={newsField} layout={{ labelCol: { span: 2 } }} />
        };
        setDwr(newdwr);
    }
    const onItemDeleteClick = (item) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '确认删除《' + item.title + '》吗？',
            onOk() {
                setLoading(true);
                const para = {
                    relkey: params.relkey,
                    key: item.key
                };
                axiosPost(6, para, 'Club', (res: any) => {
                    setLoading(false);
                    if (res.code === 0) {
                        message.destroy();
                        message.success(res.message);
                        getData(0, 20);
                        return;
                    }
                    message.destroy();
                    message.error(res.message);
                    return;
                })
            },
            onCancel() { },
        })
        return;
    }
    const onDataPost = (values, item) => {
        setDwrLoading(true);
        let formDatas = new FormData();
        let imageStr = '';
        if (values.image && values.image.length > 0) {
            if (values.image[0].originFileObj) {
                formDatas.append('file', values.image[0].originFileObj, 'image.jpg');
            } else {
                imageStr = item.image[0];
            }
        }
        values.image = imageStr;
        let html = '';
        try { html = values.content.toHTML() } catch (e) { };
        values.content = html;
        const body = {
            action: 1,
            parameters: {
                relkey: params.relkey,
                key: item !== null ? item.key : '',
                main: params.key,
                ...values,
            }
        };
        formDatas.append('json', JSON.stringify(body));
        axiosPut(formDatas, 'Club', (res: any) => {
            setDwrLoading(false);
            if (res.code === 0) {
                message.destroy();
                message.success(res.message);
                getData(0, 20);
                return;
            }
            message.destroy();
            message.error(res.message);
            return;
        })
        let newdwr: any = {
            title: '',
            visible: false,
            width: '100%',
            content: null
        };
        setDwr(newdwr);
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '社团动态'; getData(0, 100); }, [])
    return <Spin spinning={loading}>
        <div style={{ height: 'calc(100vh - 0px)', padding: 10, backgroundColor: '#f8f8f8' }}>
            <Card bodyStyle={{ padding: 10 }}>
                <Row>
                    <Col span={16}>
                        <Breadcrumb
                            separator=">"
                            style={{ lineHeight: '30px' }}
                            items={[
                                {
                                    title: '首页',
                                    path: 'index'
                                },
                                {
                                    title: params.title,
                                },
                                {
                                    title: '动态信息',
                                }
                            ]}
                            itemRender={(route, params1, routes, paths) => {
                                return <div style={{ cursor: 'pointer' }} onClick={() => {
                                    if (route.path) {
                                        navigate('/club/admin/' + route.path + '?relkey=' + params.relkey + '&module=' + params.model);
                                    }
                                    return;
                                }}>{route.title}</div>
                            }}
                        />
                    </Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                        <Button icon={<PlusOutlined />} onClick={() => { onAddClick() }} type='primary'>添加</Button>
                    </Col>
                </Row>
            </Card>
            <Card bodyStyle={{ padding: 10 }} style={{ marginTop: 10, height: 'calc(100vh - 80px)' }}>
                <Table
                    rowKey={'key'}
                    bordered
                    size='small'
                    scroll={{ y: window.innerHeight - 190, x: '100%' }}
                    columns={[
                        {
                            title: '标题',
                            dataIndex: 'title',
                            key: 'title',
                            render: (text, record) => <Paragraph ellipsis={{ rows: 1, expandable: false }}>
                                <a href={record.html} target='_blank' rel="noreferrer">{text}</a>
                            </Paragraph>,
                        },
                        {
                            title: '封面',
                            dataIndex: 'image',
                            key: 'image',
                            width: 120,
                            render: (text, record) => <Image src={text} height={30} />,
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 200,
                            fixed: 'right',
                            render: (_, item) => (
                                <Row>
                                    <Col span={24}>
                                        <Button icon={<EditOutlined />} onClick={() => { onItemEditClick(item) }} type='link'>修改</Button>
                                        <Button icon={<DeleteOutlined />} onClick={() => { onItemDeleteClick(item) }} type='link' danger>删除</Button>
                                    </Col>
                                </Row>
                            ),
                        }]}
                    pagination={{
                        current: 1,
                        pageSize: 20,
                        pageSizeOptions: [20, 50, 100, 200],
                        showTotal: (total, range) => {
                            return <>共{total}条数据，当前{range[0]}至{range[1]}条</>
                        },
                        onChange: (page, pageSize) => {
                            getData(page - 1, pageSize);
                        },
                    }}
                    dataSource={data} />
            </Card>
        </div>
        <Drawer
            title={dwr.title}
            placement="right"
            destroyOnClose
            onClose={() => { let newdrw = { ...dwr }; newdrw.visible = false; setDwr(newdrw); }}
            visible={dwr.visible}
            width={dwr.width}
            bodyStyle={{ backgroundColor: '#fff', padding: 0 }}
            className='dwr'
        >
            <Spin spinning={dwrLoading}>
                <div style={{ width: 'calc(100% - 40px)', height: '100%', padding: 16, overflow: 'hidden', overflowY: 'auto' }}>
                    {dwr.content}
                </div>
            </Spin>
        </Drawer>
    </Spin>
}