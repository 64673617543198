import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPost, axiosPut } from '../../../Utils/request';
import { Col, Empty, Row, Spin, Button, message, Card, Modal, Drawer, Input, Upload } from 'antd';
import { PlusOutlined, SettingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, UploadOutlined, EllipsisOutlined, ExclamationCircleFilled, BellOutlined, AuditOutlined, DownloadOutlined } from '@ant-design/icons';
import { getTeacherData } from '../../../Utils/FieldsUtil';
import { EditorForm } from '../../../Components/EditorForm';
import Paragraph from 'antd/es/typography/Paragraph';
export const AdminIndex: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { search } = location
    const params = queryString.parse(search);
    params.module = params.module ? params.module : '';
    const [loading, setLoading] = useState(false);
    const [dwrLoading, setDwrLoading] = useState(false);
    const [dwr, setDwr] = useState({ title: '', width: 600, visible: false, content: null });
    const [types, setTypes] = useState([]);
    const [clubs, setClubs] = useState([]);
    const getTypeData = () => {
        const para = {
            relkey: params.relkey,
            flag: 'STHDLX',
            model: params.module
        };
        axiosPost(0, para, 'Data', (res: any) => {
            if (res.code === 0) {
                res.data.list.map(item => {
                    item.value = item.CodeALLID;
                    item.label = item.CodeAllName;
                    return item;
                })
                setTypes(res.data.list);
            }
            getClubData('');
            return;
        })
    }
    const getClubData = (keyword) => {
        const para = {
            relkey: params.relkey,
            keyword: keyword,
            index: 0,
            size: 10000
        };
        axiosPost(0, para, 'Club', (res: any) => {
            setLoading(false);
            if (res.code === 0) {
                setClubs(res.data.clubs);
                return;
            }
            setClubs([]);
            return;
        })
    }
    const onItemEditClick = async (item: any) => {
        let newItem: any = JSON.parse(JSON.stringify(item));
        newItem.image = [item.icon];
        newItem.type = item.typeId;
        newItem.teacher = item.teacherKey;
        setLoading(true);
        const teachers: any = await getTeacherData(params.relkey, 'BMXX', params.module);
        let newsField = [{
            id: 'title',
            name: '标题',
            required: true,
            type: 'string'
        }, {
            id: 'desc',
            name: '简介',
            required: true,
            type: 'text'
        }, {
            id: 'type',
            name: '类型',
            required: true,
            type: 'select',
            options: types
        }, {
            id: 'teacher',
            name: '教师',
            required: true,
            type: 'select',
            options: teachers
        }, {
            id: 'count',
            name: '人数',
            required: false,
            type: 'number'
        }, {
            id: 'image',
            name: '封面',
            required: true,
            multiple: false,
            type: 'file'
        }, {
            id: 'content',
            name: '详细信息',
            required: false,
            type: 'html'
        }]
        let newdwr: any = {
            title: '修改',
            visible: true,
            width: '100%',
            content: <EditorForm currentItem={newItem} onFinish={(v: any) => { onDataPost(v, newItem) }} fields={newsField} layout={{ labelCol: { span: 2 } }} />
        };
        setDwr(newdwr);
        setLoading(false);
    }
    const onItemDeleteClick = (item) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '确认删除《' + item.title + '》吗？',
            onOk() {
                setLoading(true);
                const para = {
                    relkey: params.relkey,
                    key: item.key
                };
                axiosPost(1, para, 'Club', (res: any) => {
                    setLoading(false);
                    if (res.code === 0) {
                        message.destroy();
                        message.success(res.message);
                        getClubData('');
                        return;
                    }
                    message.destroy();
                    message.error(res.message);
                    return;
                })
            },
            onCancel() { },
        })
        return;
    }
    const onItemDetailClick = (item) => {
        window.open(item.html)
    }
    const onAddClick = async () => {
        setLoading(true);
        const teachers: any = await getTeacherData(params.relkey, 'BMXX', params.module);
        let newsField = [{
            id: 'title',
            name: '标题',
            required: true,
            type: 'string'
        }, {
            id: 'desc',
            name: '简介',
            required: true,
            type: 'text'
        }, {
            id: 'type',
            name: '类型',
            required: true,
            type: 'select',
            options: types
        }, {
            id: 'teacher',
            name: '教师',
            required: true,
            type: 'select',
            options: teachers
        }, {
            id: 'count',
            name: '人数',
            required: false,
            type: 'number'
        }, {
            id: 'image',
            name: '封面',
            required: true,
            multiple: false,
            type: 'file'
        }, {
            id: 'content',
            name: '详细信息',
            required: false,
            type: 'html'
        }]
        let newdwr: any = {
            title: '添加',
            visible: true,
            width: '100%',
            content: <EditorForm currentItem={null} onFinish={(v: any) => { onDataPost(v, null) }} fields={newsField} layout={{ labelCol: { span: 2 } }} />
        };
        setLoading(false);
        setDwr(newdwr);
    }
    const onDataPost = (values, item) => {
        setDwrLoading(true);
        let formDatas = new FormData();

        let imageStr = '';
        if (values.image && values.image.length > 0) {
            if (values.image[0].originFileObj) {
                formDatas.append('file', values.image[0].originFileObj, 'image.jpg');
            } else {
                imageStr = item.image[0];
            }
        }
        values.image = imageStr;
        let html = '';
        try { html = values.content.toHTML() } catch (e) { };
        values.content = html;
        const body = {
            action: 0,
            parameters: {
                relkey: params.relkey,
                key: item !== null ? item.key : '',
                ...values,
            }
        };
        formDatas.append('json', JSON.stringify(body));
        axiosPut(formDatas, 'Club', (res: any) => {
            setDwrLoading(false);
            if (res.code === 0) {
                message.destroy();
                message.success(res.message);
                getClubData('');
                return;
            }
            message.destroy();
            message.error(res.message);
            return;
        })
        let newdwr: any = {
            title: '',
            visible: false,
            width: '100%',
            content: null
        };
        setDwr(newdwr);

    }
    const onSearch = (value) => {
        setLoading(true);
        getClubData(value);
    }
    const onSetClick = () => {
        navigate('/club/admin/set?relkey=' + params.relkey + '&model=' + params.module);
    }
    const onExportClick = () => {
        setLoading(true);
        let body = {
            relkey: params.relkey
        };
        axiosPost(10, body, 'Club', (res: any) => {
            setLoading(false);
            if (res.code === 0) {
                window.open(res.data.url);
                return;
            }
            return;
        })
    }
    const onItemNewsClick = (item) => {
        navigate('/club/admin/news?relkey=' + params.relkey + '&key=' + item.key + '&title=' + item.title + '&model=' + params.module);
    }
    const onItemRegistClick = (item) => {
        navigate('/club/admin/regist?relkey=' + params.relkey + '&key=' + item.key + '&title=' + item.title + '&model=' + params.module + '&type=' + item.typeId);
    }
    const onImportClick = (item: any) => {
        Modal.confirm({
            title: '确认',
            icon: <ExclamationCircleOutlined />,
            content: '确认导入吗？',
            onOk() {
                setLoading(true)
                let formDatas = new FormData();
                const body = {
                    action: 2,
                    parameters: {
                        relkey: params.relkey,
                    }
                }
                formDatas.append('json', JSON.stringify(body));
                formDatas.append('file', item.fileList[0].originFileObj, item.fileList[0].originFileObj.name);
                axiosPut(formDatas, 'Club', (res: any) => {
                    setLoading(false)
                    if (res.code === 0) {
                        getClubData('');
                        message.destroy();
                        message.success(res.message);
                        return;
                    }
                    message.destroy();
                    message.error(res.message);
                    return;
                })
            },
            onCancel() { },
        })
    }
    // eslint-disable-next-line
    useEffect(() => { document.title = '社团活动'; setLoading(true); getTypeData(); }, [])
    return <Spin spinning={loading}>
        <div style={{ height: 'calc(100vh - 0px)', padding: 10, paddingTop: 0, backgroundColor: '#f8f8f8' }}>
            <Row gutter={0}>
                <Col xxl={18} xl={18} lg={12} md={12} sm={24} xs={24} style={{ marginTop: 10 }}>
                    <Input.Search
                        placeholder="请输入关键字"
                        allowClear
                        enterButton="查找"
                        onSearch={(v) => { onSearch(v) }}
                    />
                </Col>
                <Col xxl={6} xl={6} lg={12} md={12} sm={24} xs={24} style={{ textAlign: window.innerWidth < 600 ? 'left' : 'right', marginTop: 10 }}>
                    <Upload
                        name='file'
                        beforeUpload={() => false}
                        showUploadList={false}
                        multiple={false}
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                        onChange={(item: any) => { onImportClick(item) }}
                    >
                        <Button icon={<UploadOutlined />} type='default'>导入</Button>
                    </Upload>&nbsp;&nbsp;
                    <Button icon={<DownloadOutlined />} onClick={() => { onExportClick() }} type='default'>导出</Button>&nbsp;&nbsp;
                    <Button icon={<SettingOutlined />} onClick={() => { onSetClick() }} type='default'>设置</Button>&nbsp;&nbsp;
                    <Button icon={<PlusOutlined />} onClick={() => { onAddClick() }} type='primary'>添加</Button>
                </Col>
            </Row>

            <div style={{ height: window.innerWidth < 600 ? 'calc(100vh - 95px)' : 'calc(100vh - 55px)', padding: 0, overflowY: 'auto', overflowX: 'hidden', marginTop: 0 }}>
                {
                    clubs.length > 0
                        ? <Row gutter={10}>
                            {
                                clubs.map((item, index) => <Col xxl={6} xl={6} lg={8} md={12} sm={24} xs={24} style={{ marginTop: 10 }}>
                                    <Card
                                        hoverable
                                        actions={[
                                            <EditOutlined title='修改' onClick={() => { onItemEditClick(item) }} key="edit" />,
                                            <DeleteOutlined title='删除' onClick={() => { onItemDeleteClick(item) }} key="delete" />,
                                            <BellOutlined title='动态信息' onClick={() => { onItemNewsClick(item) }} key="news" />,
                                            <AuditOutlined title='报名信息' onClick={() => { onItemRegistClick(item) }} key="regist" />,
                                            <EllipsisOutlined title='详细信息' onClick={() => { onItemDetailClick(item) }} key="detail" />,
                                        ]}
                                        style={{ width: '100%' }}
                                        bordered={false}
                                        bodyStyle={{ padding: 10 }}
                                    >
                                        <Row gutter={10}>
                                            <Col span={8}>
                                                <div><img alt="example" style={{ width: '100%', maxHeight: '140px' }} src={item.icon} /></div>
                                            </Col>
                                            <Col span={16}>
                                                <Card.Meta title={item.title} description={<div>
                                                    <div>
                                                        <Paragraph ellipsis={{ rows: 2, expandable: false }}>
                                                            {item.desc}
                                                        </Paragraph>
                                                    </div>
                                                    <Row>
                                                        <Col span={8}>
                                                            类型
                                                        </Col>
                                                        <Col span={16}>
                                                            {item.type}
                                                        </Col>
                                                        <Col span={8}>
                                                            教师
                                                        </Col>
                                                        <Col span={16}>
                                                            <Paragraph style={{ color: '#999', marginBottom: 0 }} ellipsis={{ rows: 1, expandable: false }}>
                                                                {item.teacher}
                                                            </Paragraph>
                                                        </Col>
                                                        <Col span={8}>
                                                            人数
                                                        </Col>
                                                        <Col span={16}>
                                                            {item.registedCount}/{item.count}
                                                        </Col>
                                                    </Row>
                                                </div>} />
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>)
                            }
                        </Row>
                        : <Empty description="暂无数据" />
                }
            </div>
        </div>
        <Drawer
            title={dwr.title}
            placement="right"
            destroyOnClose
            onClose={() => { let newdrw = { ...dwr }; newdrw.visible = false; setDwr(newdrw); }}
            visible={dwr.visible}
            width={dwr.width}
            bodyStyle={{ backgroundColor: '#fff', padding: 0 }}
            className='dwr'
        >
            <Spin spinning={dwrLoading}>
                <div style={{ width: 'calc(100% - 40px)', height: '100%', padding: 16, overflow: 'hidden', overflowY: 'auto' }}>
                    {dwr.content}
                </div>
            </Spin>
        </Drawer>

    </Spin>
}