/* eslint-disable */
import axios from 'axios';
import { sign } from './utils';

const version = '1.0'
export const base = getProtocol().replace(":", "") === 'http' ? 'http://restfulapi.jhxhzn.com/api' : 'https://restfulapi.jhxhzn.com/api';
export const payBase = getProtocol().replace(":", "") === 'http' ? 'http://pay.jhxhzn.com/api' : 'https://pay.jhxhzn.com/api';
const config = {}
export async function axiosPost(action: number, params: any, api: string, callback: any) {
    let longTime = new Date().getTime().toString().substring(0, 10);
    let token = ''
    let body = {
        action: action,
        version: version,
        sign: '',
        time: longTime,
        parameters: params
    };
    let signStr = sign(body, longTime);
    body.sign = signStr;
    axios.defaults.headers.token = token;
    return axios.post(`${base}/${api}`, body, config).then(res => {
        callback(res.data);
    });
}

export async function axiosPut(formData: any, api: string, callback: any) {
    return axios.put(`${base}/${api}`, formData, config).then(res => {
        callback(res.data);
    });
}

export async function axiosGet(api: string, callback: any) {
    return axios.get(`${base}/${api}`, config).then(res => {
        callback(res.data);
    });
}

export async function axiosPostPay(action: number, params: any, api: string, callback: any) {
    let longTime = new Date().getTime().toString().substring(0, 10);
    let token = ''
    let body = {
        action: action,
        parameters: params
    };
    let signStr = sign(body, longTime);
    axios.defaults.headers.version = version;
    axios.defaults.headers.sign = signStr;
    axios.defaults.headers.time = longTime;
    axios.defaults.headers.token = token;
    return axios.post(`${payBase}/${api}`, body, config).then(res => {
        callback(res.data);
    });
}

export async function axiosPostAsync(action: number, params: any, api: string) {
    let longTime = new Date().getTime().toString().substring(0, 10);
    let token = ''
    let body = {
        action: action,
        version: version,
        sign: '',
        time: longTime,
        parameters: params
    };
    let signStr = sign(body, longTime);
    body.sign = signStr;
    axios.defaults.headers.token = token;
    return axios.post(`${base}/${api}`, body, config);
}
export function getProtocol() {
    return window.location.protocol; // 返回页面当前使用的协议,类似 "http:" 或 "https:"
}